@tailwind base;
@tailwind components;
@tailwind utilities;

/** Used to extend tag based rules */
@layer base
{
	body
	{
		@apply
		font-montserrat font-medium -tracking-0.27 text-1
		bg-white text-background-dark-base
		dark:bg-background-dark-base text-white
		;
	}

	input
	{
		@apply
		bg-background-dark-base text-white
		dark:bg-white dark:text-background-dark-base
		;
	}
}

/** Create a component to be used throughout the application */
@layer components
{
	@import './components/buttons';
	@import './components/typography';
}

/** Use to create new CSS utilities that are not supported */
@layer utilities
{
}

/** SCSS that isn't Tailwind format or picked up by the tool, should be added in the raw folder */
@import './raw/datatable';
