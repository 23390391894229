// Datatable
.rdt_Table
{
	.rdt_TableCell
	{
		[data-tag='allowRowEvents']
		{
			@apply w-full;
		}
	}
}
